<template>
	<div class="list">
		<div class="item" v-for="(item,index) in lightNum" :key="index">{{item}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			};
		},
		props: {
			lightNum: {
				type: Array,
				default: ()=>[1,5,3,5,6,2],
			},
		},
		methods:{
			
		}
	};
</script>

<style lang="scss" scoped>
	.list{
		width:100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.item{
			width:61px;
			height:70px;
			text-align: center;
			line-height: 70px;
			color:#fff;
			font-size: 46px;
			font-weight: bold;
			background: url('~@/assets/lightBox.png');
			background-size: cover;
		}
	}
</style>
